<template>
  <div class="step-list-container">
    <div class="container">
      <div class="step" v-for="step in stepListData" :key="step.id">
        <h3>{{ step.titleText }}</h3>
        <div class="description" v-html="step.description"></div>
        <img :src="step.stepImage[0].url" :alt="step.stepImage[0].title" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepsList',
  props: {
    stepListData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.step-list-container {
  .container {
    @apply flex flex-wrap justify-center items-center pt-6 pb-12;
    .step {
      @apply w-full px-4 mb-24 flex flex-col items-center justify-center;
      max-width: 360px;

      h3 {
        @apply uppercase mb-4;
        font-family: 'Inter SemiBold', sans-serif;
        font-size: 1.7em;
        letter-spacing: 2px;
      }

      .description {
        @apply mb-10;
        font-family: 'Inter Regular', sans-serif;
      }

      img {
        box-shadow: 0 0 12px 0 rgba(16, 19, 27, 0.66);
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .step {
        @apply w-1/2;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .step {
        @apply px-16;
        max-width: 450px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .step {
        @apply px-28;
        max-width: 615px;
      }
    }
  }
}
</style>
