<template>
  <div class="video-hero-container">
    <div
      class="container"
      v-observe-visibility="{
        callback: visibilityChanged,
        once: true,
        throttle: 600,
        intersection: {
          threshold: 0.3,
        },
      }"
    >
      <h1>{{ videoHeroData.titleText }}</h1>
      <div class="description" v-html="videoHeroData.description"></div>

      <div class="video-player-container">
        <VimeoVideoPlayer
          :video-url="videoHeroData.videoUrl"
          @loaded="videoLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VimeoVideoPlayer from '@/components/UI/VimeoVideoPlayer'
export default {
  name: 'VideoHero',
  components: { VimeoVideoPlayer },
  props: {
    videoHeroData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    videoLoaded() {
      this.$emit('loaded')
    },
    visibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.video-hero-container {
  @apply bg-cover bg-center py-16 relative mb-32;
  background-image: url('~@/assets/topograph-background.jpg');

  .container {
    @apply relative mt-8 mb-32;

    &.showing {
      h1 {
        @apply opacity-100;
      }

      .description {
        @apply opacity-100;
      }
    }

    h1 {
      @apply text-white uppercase mb-3 opacity-0;
      font-family: 'Inter Regular', sans-serif;
      font-size: 2.2em;
      letter-spacing: 2px;
      transition: all 0.7s ease-in 0.7s;
    }

    .description {
      @apply text-white opacity-0;
      font-family: 'Inter Regular', sans-serif;
      transition: all 0.7s ease-in 1.5s;
    }

    .video-player-container {
      @apply w-full absolute;
      margin-top: 25px;
      min-height: 500px;
    }
  }

  @media screen and (min-width: 600px) {
    @apply mb-48;

    .container {
      @apply mb-48;
    }
  }

  @media screen and (min-width: 900px) {
    @apply mb-60;

    .container {
      @apply mb-60 mt-16;

      h1 {
        font-size: 3em;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    @apply mb-72;

    .container {
      @apply mb-72;
    }
  }
}
</style>
