<template>
  <div id="processView">
    <VideoHero
      v-if="videoHeroData"
      :video-hero-data="videoHeroData"
      @loaded="videoHeroLoaded = true"
    />
    <StepsList :step-list-data="stepListData" />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import VideoHero from '@/components/Hero/VideoHero'
import StepsList from '@/components/Content/StepsList'
import helpers from '@/api/helpers/helpers'

export default {
  name: 'Process',
  components: { StepsList, VideoHero },
  data() {
    return {
      videoHeroData: null,
      stepListData: [],
      videoHeroLoaded: false,
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  watch: {
    videoHeroLoaded: {
      handler(newVal) {
        if (!newVal) return
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 300)
      },
    },
  },
  mounted() {
    this.loadProcessData()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadProcessData() {
      this.setProcessData(helpers.readStoredResponse('view.process'))

      api.views.loadProcess().then((response) => {
        this.setProcessData(response)
      })
    },
    setProcessData(response) {
      if (!response || !response.entry) return

      this.videoHeroData = response.entry.videoHero[0] || null
      this.stepListData = response.entry.stepList
    },
  },
}
</script>
